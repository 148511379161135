import React from 'react'
import Provider from './navigation/Index'

export const BaseUrl = 'https://shopninja.in/sunaar24/api2/public/index.php'
function App() {
  return (
    <Provider />
  )
}

export default App