import React, { useState, useEffect, createContext, useReducer } from 'react'
import axios from 'axios';
import { ErrorMsg, SuccessMsg } from '../components/Alerts';

const AuthContext = createContext()

function AuthProvider({ children }) {

    const BaseUrl = 'https://shopninja.in/sunaar24/api2/public/index.php'

    const [userToken, setUserToken] = useState()
    const [userDetails, setUserDetails] = useState()
    const [Cart_total, setCartTotal] = useState()
    const [Cart_sessid, setCartsessid] = useState()
    const [Testimonials, setTestimonials] = useState([])

    const initialFetch = {
        loading: false,
        success: false,
        error: false,
        response: false
    }
    const fetchReducer = (state, action) => {
        switch (action.type) {
            case 'setLoading': return { ...state, loading: action.value }
            case 'setSuccess': return { ...state, success: action.value }
            case 'setError': return { ...state, error: action.value }
            case 'setResponse': return { ...state, response: action.value }
            case 'reset': return initialFetch
            default: return state
        }
    }
    const [fetching, setFetching] = useReducer(fetchReducer, initialFetch)
    

    const initialAppData = {
        products: [],
        categories: [],
        banners: "",
        wishlist:[],
        cart:[],
        
        
        
    }

    const dataReducer = (state, action) => {
        switch (action.type) {
            case 'setProducts': return { ...state, products: action.value }
            case 'setCategories': return { ...state, categories: action.value }
            case 'setBanners': return { ...state, banners: action.value }
            case 'setwishlist': return { ...state, wishlist: action.value }
            case 'setcart': return { ...state, cart: action.value }
            case 'updatedWishedProduct': return {
                ...state, wishlist: [...state.wishlist].some((item) => {
                    console.log(item)
                    return item?.product_id == action.value?.product_id
                })  ?
                [...state.wishlist ]
                :
                [...state.wishlist , action.value]
                


            }
            case 'updatedcartProduct': return {
                ...state, cart: [...state.cart].some((item) => {
                    console.log(item)
                    return item.cart_id == action.value?.cart_id
                })  ?
                [...state.cart ]
                :
                [...state.cart , action.value]
            
            }

            default: return state
        }
    }

    const [appData, setAppData] = useReducer(dataReducer, initialAppData)

    const getUserToken = () => {
        let token = localStorage.getItem('idtoken')
        let user = localStorage.getItem('user')
        if (token) {
            setUserToken(token ? token : '')
            
        }
        if (user) setUserDetails(JSON.parse(user))
        return token;
    }

    const getApiData = async (cat_id) => {
        setFetching({ type: 'setLoading', value: true })

        var form = new FormData()
        form.append('filter_by', "0")
        form.append('cat_id',  "")
        await axios.post(BaseUrl + "/get_all_products", form, {
            headers: { "Content-type": "multipart/form-data" }
        }).then((response) => {
            console.log(response.data.msg, "get_all_products")
            if (response.data.status === 200) {
                setAppData({ type: 'setProducts', value: response.data.msg })
            }
        })
            .catch((error) => {
                console.log(error, 'error with api get_all_products ')
            })

        let data = new FormData();
        data.append('cat_id', "0")
        await axios.post(BaseUrl + "/get_sn_categories", data, {
            headers: { "Content-type": "multipart/form-data" }
        }).then((response) => {
            console.log(response.data.msg, "get_product_categories")
            if (response.data.status === 200) {
                setAppData({ type: 'setCategories', value: response.data.msg })
            }
        })
            .catch((error) => {
                console.log(error, 'error with api get_product_categories')
            })


        let bannerData = new FormData();
        bannerData.append('platform', "1")
        await axios.post(BaseUrl + "/get_banners", bannerData, {
            headers: { "Content-type": "multipart/form-data" }
        })
            .then((response) => {
                console.log(response.data.msg, 'get_banners recommend Api successful')
                if (response.data.status === 200) {
                    setAppData({ type: 'setBanners', value: response.data.msg })
                }
            })
            .catch((error) => {
                console.log(error, 'error while fetching get_banners recommend Api')
            })

            var userdata =  JSON.parse(localStorage.getItem('user'))
           
           
            setFetching({ type: 'setLoading', value: false })
    }

    
    const getCartProducts = async (token) => {
        setFetching({ type: 'setLoading', value: true })
        console.log(userToken, 'userToken')
        let form = new FormData()
        form.append("customer_id", token)
        await axios.post(BaseUrl + "/get_customer_cart", form, {
            headers: { "Content-type": "multipart/form-data" }
        })
            .then((response) => {
                console.log(response.data, 'get_customer_cart Api successful')
                setFetching({ type: 'setLoading', value: false })
                if (response.data.status === 200) {
                    setAppData({ type: 'setcart', value: response.data.msg?.products })
                
                    setCartTotal(response.data.msg.hasOwnProperty("cart_total") ? response.data.msg?.cart_total : 0 )
                    // setMapArray(true)
                }
                else{
                    setCartTotal(response.data.msg.hasOwnProperty("cart_total") ? response.data.msg?.cart_total : 0 )
                    //  setFetching({ type: 'setError', value: { heading: "No products found!", data: response.data.msg } })
                }
            })
            .catch((error) => {
                // setFetching({ type: 'setError', value: { heading: "No products found!", data: error.message } })
                console.log(error, 'error while fetching getcart api')
                setFetching({ type: 'setLoading', value: false })
            })

            let formD = new FormData()
            formD.append("cust_id", token)
            await axios.post(BaseUrl + "/get_customer_wishlist", formD, {
                headers: { "Content-type": "multipart/form-data" }
            })
                .then((response) => {
                    console.log(response.data, 'get_customer_wishlist Api successful')
                    
                    if (response.data.status === 200) {
                        setAppData({ type: 'setwishlist', value: response.data.msg })
                        // setMapArray(true)
                    }
                    // else setFetching({ type: 'setError', value: { heading: "No products found!", data: response.data.msg } })
                })
                .catch((error) => {
                   
                    console.log(error, 'error while fetching get_customer_wishlist api')
                  
                })
                setFetching({ type: 'setLoading', value: false })
    }
    useEffect(() => {
       const token =  getUserToken()
       getApiData()
        if(token){

        
        
        getCartProducts(token)
        }
    }, [])

    const sendOtp = async (mobile, navigate) => {
        setFetching({ type: 'setLoading', value: true })
        var form = new FormData()
        form.append('mob', mobile);
        await axios.post(BaseUrl + "/tr_send_otp", form, {
            headers: { "Content-type": "multipart/form-data" }
        })
            .then((response) => {
                setFetching({ type: 'setLoading', value: false })
                console.log(response.data, 'tr_send_otp api')
                if (response.data.status == 200) {
                    navigate("../verify/" + response.data.msg.cust_id, { replace: true })
                }
                else {
                    setFetching({ type: 'setError', value: { heading: response.data.msg } })
                }
            })
            .catch((error) => {
                console.log(error, 'error while fetching Api')
                setFetching({ type: 'setLoading', value: false })
                setFetching({ type: 'setError', value: { heading: error.message } })
            })
    }

    return (
        <AuthContext.Provider value={{
            setTestimonials,Testimonials,
            setCartTotal,Cart_total,
            appData, setAppData, fetching, setFetching, BaseUrl,
            userToken, setUserToken, userDetails, setUserDetails,
            login: async (email, password, navigate) => {
                console.log(email, password, 'username,password entered are')
                setFetching({ type: 'setLoading', value: true })
                var form = new FormData()
                form.append('email', email);
                form.append('password', password);
                form.append('device_id', '');
                await axios.post(BaseUrl + "/customer_login", form, {
                    headers: { "Content-type": "multipart/form-data" }
                })
                    .then((response) => {
                        console.log(response.data, 'customer_login Api successful')
                        setFetching({ type: 'setLoading', value: false })
                        if (response.data.status === 200) {
                            setUserDetails(response.data.msg)
                            setUserToken(response.data.msg.cust_id)
                            localStorage.setItem("idtoken", response.data.msg.cust_id)
                            localStorage.setItem("user", JSON.stringify(response.data.msg))
                        navigate('/')
                        window.location.reload()
                            
                            
                           
                        }
                        else {
                            setFetching({ type: 'setError', value: { heading: response.data.msg } })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'error while fetching Api')
                        setFetching({ type: 'setLoading', value: false })
                        setFetching({ type: 'setError', value: { heading: error.message } })
                    })

            },

            register: async (name, email, mobile, password, navigate) => {
                console.log(email, password, 'username,password entered are')
                setFetching({ type: 'setLoading', value: true })
                var form = new FormData()
                form.append('name', name);
                form.append('email', email);
                form.append('mobile', mobile);
                form.append('password', password);
                form.append('device_id', '');
                form.append('platform', '2');
                await axios.post(BaseUrl + "/customer_signup", form, {
                    headers: { "Content-type": "multipart/form-data" }
                })
                    .then((response) => {
                        console.log(response.data, 'customer_signup Api successful')
                        setFetching({ type: 'setLoading', value: false })
                        if (response.data.status === 200) {
                            setUserDetails(response.data.msg)
                            localStorage.setItem("user", JSON.stringify(response.data.msg))
                            // sendOtp(mobile, navigate)
                            navigate("../verify/" + response.data.msg.cust_id, { replace: true })
                        }
                        else {
                            setFetching({ type: 'setError', value: { heading: response.data.msg } })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'error while fetching Api')
                        setFetching({ type: 'setLoading', value: false })
                        setFetching({ type: 'setError', value: { heading: error.message } })
                    })
            },
            verifyOtp: async (mobile, otp, navigate) => {
                console.log(mobile, otp, 'mobile, otp entered are')
                setFetching({ type: 'setLoading', value: true })
                navigate("/", { replace: true })
                setUserToken(userDetails.cust_id)
                window.location.reload()
                localStorage.setItem("idtoken", userDetails.cust_id);
                setFetching({ type: 'setLoading', value: false })
                // var form = new FormData()
                // form.append('mob', mobile);
                // form.append('otp', otp);
                // await axios.post(BaseUrl + "/tr_verify_otp", form, {
                //     headers: { "Content-type": "multipart/form-data" }
                // })
                //     .then((response) => {
                //         console.log(response.data.msg, 'tr_verify_otp Api successful')
                //         setFetching({ type: 'setLoading', value: false })
                //         if (response.data.status === 200) {
                //             setUserToken(userDetails.cust_id)
                //             AsyncStorage.setItem('userToken', userDetails.cust_id)
                //         }
                //         else {
                //             setFetching({ type: 'setError', value: { heading: "Can't Verify Otp !", data: response.data.msg } })
                //         }
                //     })
                //     .catch((error) => {
                //         console.log(error, 'error while fetching Api')
                //         setFetching({ type: 'setLoading', value: false })
                //         setFetching({ type: 'setError', value: { heading: "Can't Verify Otp !", data: error.message } })
                //     })
            },
            logout: (navigate) => {
                sessionStorage.clear()
                localStorage.clear()
                setUserToken()
                setUserDetails()
                navigate('/login', { replace: true })
                // window.location.reload()
            }

        }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthProvider, AuthContext }